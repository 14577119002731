import { useLogoutAction } from '@app/hooks/useLogoutAction';
import { useAppSelector } from '@app/store';
import type { IUser } from '@app/types';
import { Menu, Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { useCallback } from 'react';
import { ImShuffle } from 'react-icons/im';
import { Link, useMatch } from 'react-router-dom';
import { useDialogState } from '../../hooks/useDialogState';
import { UserProfileForm } from '../../pages/profile/UserProfileForm';
import { AppDialog } from './AppDialog';
import { CustomerPortalLink } from './CustomerPortalLink';

interface IProps {
  user: IUser;
}

export default function UserMenu({ user }: IProps) {
  const logoutAction = useLogoutAction();

  const isAccountOwner = useAppSelector((state) => state.auth.user?.tenantOwner);

  const onPremise = useAppSelector((state) => state.auth.user?.currentTenant?.onPremise);

  const hasCompanySwitcher = user.tenantCount > 1 || user.tenantCount === -1;

  const linkClassName = 'block w-full px-3 py-2 text-right';

  const [profileDialogOpen, openProfileDialog, onRequestProfileDialogClose] = useDialogState();

  const isProfilePage = useMatch('/profile');

  const handleProfileLinkClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (!isProfilePage) {
        event.preventDefault();
        openProfileDialog();
      }
    },
    [openProfileDialog, isProfilePage]
  );
  return (
    <div>
      <Menu as="div" className="relative">
        {({ open }) => (
          <>
            <Menu.Button className="block w-full p-1 text-right text-base font-bold normal-case text-white">
              <div>Welcome, {user.firstName}</div>
              <div className="text-right text-xs font-bold uppercase text-app-gray-medium !no-underline">
                {user.currentTenant?.name}
              </div>
            </Menu.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Menu.Items className="absolute right-0 mt-2 min-w-max bg-white text-right text-sm shadow-lg ring-1 ring-black/5 focus:outline-none">
                <ul className="divide-y divide-gray-100">
                  <li className="bg-app-gray-pale p-1">
                    {!hasCompanySwitcher && (
                      <div className={linkClassName}>
                        <div className="w-full text-xs font-bold uppercase text-app-gray-dark">Your Company</div>
                        <div className="flex items-center justify-end text-base font-bold text-gray-800">
                          {user.currentTenant?.name}
                        </div>
                      </div>
                    )}
                    {hasCompanySwitcher && (
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            className={clsx(linkClassName, {
                              'bg-app-blue-primary text-white': active,
                            })}
                            to="/company-selection"
                          >
                            <div
                              className={clsx('w-full text-xs font-bold uppercase  ', {
                                'text-app-gray-dark': !active,
                                'text-white': active,
                              })}
                            >
                              Current Company
                            </div>
                            <div
                              className={clsx('flex items-center justify-end text-base font-bold   ', {
                                'text-gray-800': !active,
                                'text-white': active,
                              })}
                            >
                              <ImShuffle
                                className={clsx('mr-2 text-xs font-bold ', {
                                  'text-app-blue-medium': !active,
                                  'text-white': active,
                                })}
                              />
                              {user.currentTenant?.name}
                            </div>
                          </Link>
                        )}
                      </Menu.Item>
                    )}
                  </li>
                  <li className="p-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/profile"
                          className={clsx(linkClassName, {
                            'bg-app-blue-primary text-white': active,
                          })}
                          onClick={handleProfileLinkClick}
                        >
                          Your Profile
                        </Link>
                      )}
                    </Menu.Item>
                  </li>
                  <li className="p-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/profile/password"
                          className={clsx(linkClassName, {
                            'bg-app-blue-primary text-white': active,
                          })}
                        >
                          Change Password
                        </Link>
                      )}
                    </Menu.Item>
                  </li>
                  {isAccountOwner && !onPremise && (
                    <>
                      <li className="p-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/account/owners"
                              className={clsx(linkClassName, {
                                'bg-app-blue-primary text-white': active,
                              })}
                            >
                              Manage Account Owners
                            </Link>
                          )}
                        </Menu.Item>
                      </li>
                      <li className="p-1">
                        <Menu.Item>
                          {({ active }) => (
                            <CustomerPortalLink
                              enabled={open}
                              className={clsx(linkClassName, {
                                'bg-app-blue-primary text-white': active,
                              })}
                            >
                              Manage Subscription
                            </CustomerPortalLink>
                          )}
                        </Menu.Item>
                      </li>
                      <li className="p-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/new-company"
                              className={clsx(linkClassName, {
                                'bg-app-blue-primary text-white': active,
                              })}
                            >
                              Register a New Company
                            </Link>
                          )}
                        </Menu.Item>
                      </li>
                    </>
                  )}
                  <li className="p-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={logoutAction}
                          className={clsx(linkClassName, {
                            'bg-app-blue-primary text-white': active,
                          })}
                        >
                          Sign Out
                        </button>
                      )}
                    </Menu.Item>
                  </li>
                </ul>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <AppDialog open={profileDialogOpen} onRequestClose={onRequestProfileDialogClose} title="Your Profile" size="4xl">
        <UserProfileForm />
      </AppDialog>
    </div>
  );
}
