import {
  PERMISSION_ALL_SYSTEM_FIELDS_CREATE,
  PERMISSION_ALL_SYSTEM_FIELDS_DELETE,
  PERMISSION_ALL_SYSTEM_FIELDS_EDIT,
  PERMISSION_ALL_SYSTEM_FIELDS_VIEW,
  PERMISSION_EXPENSES_VIEW,
  PERMISSION_PROJECT_VIEW,
  PERMISSION_SUBS_VIEW,
  PERMISSION_TIMESHEETS_VIEW,
  PERMISSION_WORKLOAD_VIEW,
} from '@app/config';
import { useAppSelector } from '@app/store';
import { clsx } from 'clsx';
import { NavLink } from 'react-router-dom';
import { useTenantTypeString } from '../../hooks/useTenantTypeString';
import { HelpMenu } from './HelpMenu';
import { Restrict } from './Restrict';
import { TenantTypeString } from './TenantTypeString';
import UserMenu from './UserMenu';

const navLinkClassName =
  'text-white text-13 uppercase font-bold flex items-center border-t-4 border-b-4 border-transparent hover:text-app-blue-primary cursor-pointer tracking-wide relative';
const activeLinkClassName = 'text-app-blue-primary border-b-app-blue-primary';

const className = ({ isActive }: { isActive: boolean }) => clsx(navLinkClassName, { [activeLinkClassName]: isActive });

export default function TopNav() {
  const user = useAppSelector((state) => state.auth.user);

  const subsUrl = useTenantTypeString('/subs', '/vendors');

  return (
    <div className="absolute left-0 top-0 z-main-nav h-16 w-full min-w-root bg-app-blue-dark 2xl:fixed">
      <div className="flex h-full justify-between px-8">
        <nav className="flex">
          <NavLink to="/" className="mr-16 flex shrink-0 items-center">
            <img src="/images/logo-inscope.svg" width="138" height="40" alt="InScope" />
          </NavLink>

          {user !== null && (
            <div className="flex space-x-8">
              <Restrict
                permissions={[PERMISSION_PROJECT_VIEW]}
                element={
                  <NavLink className={className} to="/projects">
                    Projects
                  </NavLink>
                }
              />

              <Restrict
                featureFlag="budgets"
                tenantType="Owner"
                element={
                  <NavLink className={className} to="/budgets">
                    Budgets
                  </NavLink>
                }
              />

              <Restrict
                permissions={[PERMISSION_WORKLOAD_VIEW]}
                element={
                  <NavLink className={className} to="/workloads">
                    Workloads
                  </NavLink>
                }
              />

              <Restrict
                permissions={[PERMISSION_TIMESHEETS_VIEW]}
                element={
                  <NavLink className={className} to="/timesheets/entry">
                    Timesheets
                  </NavLink>
                }
              />

              <Restrict
                permissions={[PERMISSION_EXPENSES_VIEW]}
                element={
                  <NavLink className={className} to="/expense-reports">
                    Expenses
                  </NavLink>
                }
              />

              <Restrict
                permissions={[PERMISSION_SUBS_VIEW]}
                element={
                  <NavLink className={className} to={subsUrl}>
                    <TenantTypeString vendor="Subs" owner="Vendors" />
                  </NavLink>
                }
              />

              <Restrict
                tenantType="Owner"
                version="OnPremise"
                element={
                  <NavLink className={className} to="/prioritize">
                    Prioritize
                  </NavLink>
                }
              />

              <Restrict
                permissions={[
                  PERMISSION_ALL_SYSTEM_FIELDS_VIEW,
                  PERMISSION_ALL_SYSTEM_FIELDS_CREATE,
                  PERMISSION_ALL_SYSTEM_FIELDS_DELETE,
                  PERMISSION_ALL_SYSTEM_FIELDS_EDIT,
                ]}
                element={
                  <NavLink className={className} to="/admin">
                    System
                  </NavLink>
                }
              />
            </div>
          )}
        </nav>

        <div className="ml-auto flex items-center gap-4">
          <HelpMenu />

          {user !== null && <UserMenu user={user} />}
        </div>
      </div>
    </div>
  );
}
